

import * as Sentry from '@sentry/vue';
import type { Router } from 'vue-router';
import CONFIG from '@jcc/config';
import { defineServerUrl } from '@jcc/tools/utils';


type RouteOptions = {
  errorProcessing?: boolean;
  statusTrack?: boolean;
};

class ApiService {
  #router!: Router;

  public reconnect(): Promise<void> {
    const reconnection = async (
      res: () => void, _: () => void, reconnectsCount = 0
    ): Promise<void> => {
      console.debug(`Reconnects amount: [${reconnectsCount}]`);
      try {
        await this.checkConnection();
        res();
      } catch (e) {
        const timerId = setTimeout(() => {
          clearTimeout(timerId);
          reconnection(res, _, reconnectsCount + 1);
        }, CONFIG.SERVER_RECONNECTION_PERIOD);
      }
    };
    return new Promise(reconnection);
  }

  setRouter(router: Router): void {
    if (!this.#router) {
      this.#router = router;
    }
  }

  async checkConnection(): Promise<void> {
    return this.#processRoute('/status');
  }

  async createRoom(roomId: string): Promise<{ password: string }> {
    return this.#processRoute('/rooms/password/%room_id%', { errorProcessing: true }, roomId);
  }

  async joinRoom(password: string): Promise<{ room_id?: string, detail?: { errorType: string } }> {
    return this.#processRoute('/rooms/tryenter/provider/%password%', { statusTrack: false }, password);
  }

  #processError(withRedirect: boolean, e?: Error): void {
    if (withRedirect) {
      // this.#router.push({
      //   name: CONFIG.PATHS_NAMES.noConnection,
      //   params: { fromPath: (this.#router.currentRoute.value.name ?? '') as string },
      // });
    } else {
      throw new Error(e?.message ?? 'Нет ответа от сервера');
    }
  }

  /* eslint-disable-next-line consistent-return */
  async #processRoute(
    route: string,
    options: RouteOptions = { errorProcessing: false, statusTrack: true },
    ...args: Array<string | number>
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  ): Promise<any> {
    const paramsNames = (
      route.match(/%.+%/g) as string[]
    )[0].split('/').map((p) => p.replace(/%/g, ''));
    const routeWithParams = paramsNames
      .reduce((acc, paramName, index) => (
        acc.replace(`%${paramName}%`, `${args[index]}`)
      ), defineServerUrl('REST') + route);
    try {
      const res = await fetch(routeWithParams);
      if (res.status === 200 || !options.statusTrack) {
        return await res.json();
      }
      this.#processError(!!options.errorProcessing);
    } catch (e) {
      console.error('Ошибка при запросе к REST API');
      Sentry.captureMessage('Ошибка при запросе к REST API');
      Sentry.captureException(e);
      this.#processError(!!options.errorProcessing, e as Error);
    }
  }
}


export default new ApiService();
